import React from 'react'
import ReactMarkdown from 'react-markdown'
import {classes} from 'src/utilities/classes'
import {RichText} from 'prismic-reactjs'
import styles from 'src/css/rich_text.module.css'

export default function Markdown({children, className, ...props}) {
  return (
    <div className={classes(className, styles.root)} {...props}>
      <ReactMarkdown source={RichText.asText(children || [])} escapeHtml={false}/>
    </div>
  )
}